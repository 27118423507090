<template>
    <div>
        <div class="single-file-alert3 alert" v-if="!isRegistCard && this.$store.state.trialStatus">
            <div class="single-file-box3 alert_box">
                <h4>거의 다왔어요!<br>그래도 그만하시겠어요?</h4>
                <div class="single-file-btn3">
                    <p id="color-text" @click="$emit('closeCancel')">그렇다면 계속 할래요</p>
                    <p @click="updateSpaceInfo()" style="font-size:13px;" v-if="this.spaceId != null">나중에 할래요</p>
                    <p @click="insertSpace()" style="font-size:13px;" v-else>나중에 할래요</p>
                </div>
            </div>
        </div>
        <div class="single-file-alert alert" v-else>
            <div class="single-file-box alert_box">
                <h3>등록중인 스페이스를 저장할까요?</h3>
                <div class="single-file-btn">
                    <p @click="linkMySpace()">아니오</p>
                    <p id="color-text" @click="updateSpaceInfo()" v-if="this.spaceId != null">네</p>
                    <p :class="{ disabled: disabledInsert === true }" id="color-text" @click="insertSpace()" v-else>네</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "axios";
export default {
  name: 'cnacelCheckModal2',
  emits: ['deletspace'],
  data () {
    return {
      disabledInsert: false
    };
  },
  props: {
    spaceInfo: Object,
    spaceId: String,
    spaceType: String,
    isRegistCard: Boolean,
    curationinfo: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    handleError (err, methodname = '') {
      this.disabledInsert = false;
      this.$emit('closeCancel');
      if (err.response) {
        const { data } = err.response;
        const { resultCd, resultMsg } = data;
        if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
          this.$emit('deletspace', resultMsg);
          return false;
        }
      }
      console.log(`${methodname} error : `, err);
    },
    insertSpace () {
      this.disabledInsert = true;
      var spaceType = this.spaceType;
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/createSpaceInfo", { userId, spaceType }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          var spaceId = res.data.result;
          localStorage.setItem("spaceId", spaceId);
          this.spaceInfo.spaceId = spaceId;

          axios
            .post("/api/profile/createCurationInfo", { spaceId }, { headers })
            .then((response) => {
              // console.log(response);

              axios
                .post("/api/profile/createPreferMusicList", { spaceId }, { headers })
                .then((res1) => {
                  var spaceInfo = this.spaceInfo;

                  axios
                    .post("/api/profile/updateSpaceInfo", spaceInfo, { headers })
                    .then((res) => {
                      if (res.data.resultCd === '0000') {
                        this.disabledInsert = false;
                        this.$emit('closeCancel');
                        this.$emit('showCancel');
                      }
                    })
                    .catch((err) => this.handleError(err, 'insertSpace'));
                })
                .catch((err1) => {
                  this.disabledInsert = false;
                  console.log(err1);
                });
            })
            .catch((error) => {
              this.disabledInsert = false;
              console.log(error);
            });
        })
        .catch((err) => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    updateSpaceInfo () {
      // 매장 정보 수정
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var spaceInfo = this.spaceInfo;

      axios
        .post("/api/profile/updateSpaceInfo", spaceInfo, { headers })
        .then((res) => {
          // console.log(res.data);
          this.$emit('closeCancel');
          this.$emit('showCancel');
        })
        .catch((err) => this.handleError(err, 'updateSpaceInfo'));
    },
    linkMySpace () {
      var spaceId = this.spaceId;
      if (spaceId != null) {
        var aToken = VueCookies.get("aToken");
        var headers = { "X-AUTH-TOKEN": aToken };

        axios
          .post("/api/profile/deleteMySpaceInfo", { spaceId }, { headers })
          .then((res) => {
            this.$emit('closeCancel');
            this.$router.push('/myspace');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$emit('closeCancel');
        // this.$router.push('/myspace');
        history.back();
      }
    }
  }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

/* 1줄 모달 */
.single-file-box3 {
  box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
  padding: 24px 24px 8px 24px;
  width: 360px;
}

.single-file-box3 h4 {
  padding-bottom: 24px;
  color: rgba(255, 255, 255, 0.80);
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: left;
}

.single-file-btn3 {
  justify-content: center;
  align-items: center;
}

.single-file-btn3 p {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  line-height: 60px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

.single-file-btn3 #color-text {
  color: #FF2D55;
  font-weight: 600;
  background: #FF2D55;
  color: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 15px;
  line-height: 48px;
}

.single-file-check3 p {
  width: 100%;
  height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF2D55;
  font-size: 16px;
  cursor: pointer;
}

.single-file-box3 .single-file-content3 {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.70);
  padding: 0 5% 5%;
}

@media all and (max-width: 500px) {
  .single-file-box3 {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
    padding: 24px 20px 8px 20px;
    width: 320px;
  }

  .single-file-box3 h4 {
    padding-bottom: 24px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
  }

  .single-file-btn3 {
    justify-content: center;
    align-items: center;
  }

  .single-file-btn3 p {
    font-size: 15px;
  }

  .single-file-btn3 #color-text {
    font-size: 16px;
  }

  .single-file-check3 p {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF2D55;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
