<template>
    <div class="store_search_modal modal">
        <CheckSpaceNm v-if="checkSpaceNm" :spaceInfo="spaceInfo" @closeModal="checkSpaceNm = false" @closeMap="closeMap()" />
        <div class="store_search_box modal_box">
            <div class="st_search_title modal_title">
                <h2 style="margin-bottom: 0px">매장명 검색</h2>
                <div class="modal_close_btn2" @click="$emit('closeMap2')">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
            </div>
            <div class="search_input">
                <img src="/media/img/search_icon.svg" alt="" class="search_img" @click="searchStore" />
                <input ref="search" type="text" placeholder="매장명으로 검색해주세요" @keyup.enter="searchStore" v-model="childSpaceName" />
            </div>
            <div class="map-area">
                <div class="searchbox">
                    <div class="results">
                        <div class="place" v-for="rs in search.results" :key="rs.id" @click="showPlace(rs)">
                            <div class="spaceNm">{{ rs.place_name }}</div>
                            <div class="addr">{{ rs.address_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
import VueCookies from "vue-cookies";
import CheckSpaceNm from "@/components/modal/Map/checkSpaceNm.vue";

export default defineComponent({
  props: {
    spaceName: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      spaceInfo: {},
      search: {
        keyword: null,
        pgn: null,
        results: []
      },
      level: 8,
      center: {
        lat: 37.566826,
        lng: 126.9786567
      },
      mapInstance: null,
      checkSpaceNm: false,
      childSpaceName: ""
    };
  },
  created () {
    this.childSpaceName = this.spaceName;
  },
  components: {
    CheckSpaceNm
  },
  watch: {
    "level" (cur, prev) {
      // console.log(`[LEVEL CHANGED] ${prev} => ${cur}`);
      this.mapInstance.setLevel(cur);
    },
    spaceName (val) {
      if (val) {
        this.childSpaceName = val;
      }
    }
  },
  methods: {
    closeMap () {
      this.$emit("getSpaceInfo", this.spaceInfo);
      this.$emit('closeMap');
    },
    zoom (delta) {
      // delta : 1 or -1
      // console.log("[delta]", delta);
      const level = Math.max(3, this.level + delta); // min level 3
      this.level = level;
      // console.log(this.level);
    },
    showPlace (place) {
      // console.log()
      var spaceNm = place.place_name;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      this.spaceInfo.spaceNm = spaceNm;
      this.spaceInfo.spaceAddr = place.address_name;
      this.spaceInfo.x = place.x;
      this.spaceInfo.y = place.y;

      axios
        .post("/api/profile/checkSpaceNm", { spaceNm }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              this.closeMap();
            } else {
              this.checkSpaceNm = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
      /* this.center = {
        lat: place.y,
        lng: place.x
      };
      this.getMap(); */
    },
    searchStore () {
      var kakao = window.kakao;
      kakao.maps.load(() => {
        const keyword = this.childSpaceName;
        if (keyword.length === 0) {
          alert('키워드를 입력해주세요!');
        }

        const ps = new window.kakao.maps.services.Places();

        ps.keywordSearch(keyword, (data, status, pgn) => {
          if (data.length > 0) {
            this.search.keyword = keyword;
            this.search.pgn = pgn;
            this.search.results = data;

            // this.pagination(pgn);
          } else {
            alert("검색결과가 존재하지 않습니다.");
          }
        });
      });
    },
    /*
    pagination (pagination) {
      var paginationEl = document.getElementById('pagination');
      var fragment = document.createDocumentFragment();
      var i;

      //기존에 추가된 페이지번호를 삭제합니다
      while (paginationEl.hasChildNodes()) {
        paginationEl.removeChild(paginationEl.lastChild);
      }

      for (i = 1; i <= pagination.last; i++) {
        var el = document.createElement('a');
        el.href = "#";
        el.innerHTML = i;

        if (i === pagination.current) {
          el.className = 'on';
        } else {
          el.onclick = (function (i) {
            return function () {
              pagination.gotoPage(i);
            };
          })(i);
        }
        fragment.appendChild(el);
      }
      paginationEl.appendChild(fragment);
    },
    */
    getCurrentPosition () {
      var kakao = window.kakao;
      // console.log(this.$refs.map);
      kakao.maps.load(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center.lat = position.coords.latitude; // 위도
          this.center.lng = position.coords.longitude; // 경도

          var container = this.$refs.map;
          var options = {
            center: new kakao.maps.LatLng(this.center.lat, this.center.lng),
            level: this.level
          };

          this.mapInstance = new kakao.maps.Map(container, options);
          // console.log(this.mapInstance);
        });
      });
    },
    getMap () {
      var kakao = window.kakao;
      kakao.maps.load(() => {
        // console.log(this.$refs.map);

        var container = this.$refs.map;
        var options = {
          center: new kakao.maps.LatLng(this.center.lat, this.center.lng),
          level: this.level
        };

        this.mapInstance = new kakao.maps.Map(container, options);
        // console.log(this.mapInstance);
      });
    }
  },
  mounted () {
    var spaceName = this.spaceName;
    this.$nextTick(() => this.$refs.search.focus());
    if (!window.kakao) {
      // console.log("script 로딩중");
      const script = document.createElement("script");
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=9d67727587f68495b58ce27d5adc27e1&autoload=false&libraries=services";
      document.head.appendChild(script);
    }
    // this.getMap();
    // 입력 값 있을시 검색
    console.log();
    setTimeout(() => {
      if (spaceName.length > 0) {
        var kakao = window.kakao;
        kakao.maps.load(() => {
          // console.log(e.target.value);
          const keyword = spaceName;

          const ps = new window.kakao.maps.services.Places();

          ps.keywordSearch(keyword, (data, status, pgn) => {
            if (data.length > 0) {
              this.search.keyword = keyword;
              this.search.pgn = pgn;
              this.search.results = data;

              // this.pagination(pgn);
            } else {
              alert("검색결과가 존재하지 않습니다.");
            }
          });
        });
      } else {
        this.spaceName = "";
      }
    }, 1000);
  }
});
</script>

<style scoped>
.modal_close_btn2:hover {
  cursor: pointer;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.08);
}

.store_search_box {
  height: 570px;
}

.kmap {
  flex: 1 1 auto;
  height: 600px;
}

.map-area {
  display: flex;
  position: relative;
}

.map-area .searchbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 370px;
  z-index: 10000;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.map-area .results {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-top: 24px;
}

.map-area .results .place {
  padding: 12px 0;
  cursor: pointer;
}

.map-area .results .place .spaceNm {
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 8px;
  cursor: pointer;
}

.map-area .results .place .addr {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 300;
}

#pagination {
  margin: 10px auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

#pagination a {
  display: inline-block;
  margin-right: 10px;
}

#pagination .on {
  font-weight: bold;
  cursor: default;
  color: #777;
}

.results::-webkit-scrollbar {
  display: block;
}

.results::-webkit-scrollbar {
  width: 4px;
}

.results::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 5px;
}

.results::-webkit-scrollbar-track {
  background: transparent;
}

.search_input {
  position: relative;
}

.search_img {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
  cursor: pointer;
}

@media all and (max-width: 500px) {
  .store_search_box {
    overflow: auto;
  }

  .map-area .searchbox {
    height: 660px;
  }

  .store_search_box {
    height: 100%;
  }

  .results::-webkit-scrollbar {
    display: block;
  }

  .results::-webkit-scrollbar {
    width: 4px;
  }

  .results::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 5px;
  }

  .results::-webkit-scrollbar-track {
    background: transparent;
  }

  .search_img {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}
</style>
