<template>
    <div class="time_modal modal close_time">
        <div class="time_modal_box modal_box">
            <div class="select_box">
                <div class="select-box-area">
                    <div class="label select-box" @mouseover.once="timeSet">{{ time.hour }} 시</div>
                    <div class="select-box-dropDown">
                        <ul class="optionList">
                            <li class="optionItem"><p>00 시</p></li>
                            <li class="optionItem"><p>01 시</p></li>
                            <li class="optionItem"><p>02 시</p></li>
                            <li class="optionItem"><p>03 시</p></li>
                            <li class="optionItem"><p>04 시</p></li>
                            <li class="optionItem"><p>05 시</p></li>
                            <li class="optionItem"><p>06 시</p></li>
                            <li class="optionItem"><p>07 시</p></li>
                            <li class="optionItem"><p>08 시</p></li>
                            <li class="optionItem"><p>09 시</p></li>
                            <li class="optionItem"><p>10 시</p></li>
                            <li class="optionItem"><p>11 시</p></li>
                            <li class="optionItem"><p>12 시</p></li>
                            <li class="optionItem"><p>13 시</p></li>
                            <li class="optionItem"><p>14 시</p></li>
                            <li class="optionItem"><p>15 시</p></li>
                            <li class="optionItem"><p>16 시</p></li>
                            <li class="optionItem"><p>17 시</p></li>
                            <li class="optionItem"><p>18 시</p></li>
                            <li class="optionItem"><p>19 시</p></li>
                            <li class="optionItem"><p>20 시</p></li>
                            <li class="optionItem"><p>21 시</p></li>
                            <li class="optionItem"><p>22 시</p></li>
                            <li class="optionItem"><p>23 시</p></li>
                        </ul>
                    </div>
                </div>
                <div class="select-box-area2">
                    <div class="label2 select-box2" @mouseover.once="timeSet2">{{ time.minute }} 분</div>
                    <div class="select-box-dropDown2">
                        <ul class="optionList">
                            <li class="optionItem2"><p>00 분</p></li>
                            <li class="optionItem2"><p>10 분</p></li>
                            <li class="optionItem2"><p>20 분</p></li>
                            <li class="optionItem2"><p>30 분</p></li>
                            <li class="optionItem2"><p>40 분</p></li>
                            <li class="optionItem2"><p>50 분</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btn_area">
                <!-- <button id="prev_btn">취소</button> -->
                <button @click="selectTime">적용</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'endPc',
  props: {
    userInfo: Object
  },
  data () {
    return {
      time: {
        hour: '00',
        minute: '00'
      }
    };
  },
  created () {
    this.time.hour = this.userInfo.endTime.substring(0, 2);
    this.time.minute = this.userInfo.endTime.substring(3, 5);
  },
  methods: {
    selectTime () {
      this.$emit('insertPc', this.time);
      this.$emit('closePc');
    },
    timeSet () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.time.hour = item.textContent.substring(0, 2);
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    timeSet2 () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.time.minute = item.textContent.substring(0, 2);
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
};
</script>

<style scoped>
  .select-box-dropDown ul {
    width: 100%;
  }
  .select-box-area {
    width: 49.8%;
  }
  .select-box-area2 {
    width: 49.8%;
  }
  .time_modal_box .select_box {
    background: #383838;
    width: 100%;
    margin-bottom: 6px;
  }
</style>
