<template>
    <div class="store_search_modal modal">
        <CheckFranchise v-if="checkFranchiseStatus" @closeCheckFranchise="checkFranchiseStatus=false" @accept="selectBrand"/>
        <div class="store_search_box modal_box">
            <div class="st_search_title modal_title">
                <h2 style="margin-bottom: 0px">브랜드 검색</h2>
                <div class="modal_close_btn2" @click="$emit('closeFranchise')">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
            </div>
            <div class="search_input">
                <img src="/media/img/search_icon.svg" alt="" class="search_img" @click="filterBrandList"/>
                <input
                    ref="search"
                    type="text"
                    placeholder="브랜드명을 검색해주세요."
                    v-model="brandName"
                    @keyup.enter="filterBrandList"
                />
            </div>
            <div class="map-area">
                <div class="searchbox">
                    <div class="results">
                        <div class="place">
                            <div class="spaceNm" style="cursor: pointer;" v-for="(brand, i) in brandList" :key="i" @click="checktBrand(brand)" v-html="brand.brandNm" />
                        </div>
                    </div>
                    <!-- <div id="pagination"></div> -->
                </div>
                <!-- <div class="kmap" ref="map"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
import CheckFranchise from './CheckFranchise.vue';
import VueCookies from "vue-cookies";
import axios from "axios";

export default {
  name: 'franchise',
  data () {
    return {
      brandName: '',
      brandList: [],
      brandInfo: {},
      brandListData: [],
      checkFranchiseStatus: false
    };
  },
  props: [
    'searchBrandNm'
  ],
  components: {
    CheckFranchise
  },
  mounted () {
    this.brandName = this.searchBrandNm;
    this.$nextTick(() => this.$refs.search.focus());
    this.getBrandList();
  },
  methods: {
    getBrandList () {
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var spaceType = 'Store';

      axios
        .post("/api/profile/getBrandList", { spaceType }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.brandListData = res.data.result;

          if (this.brandName !== '') {
            this.filterBrandList();
          } else {
            // alert('검색어를 입력해주세요.');
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    filterBrandList () {
      if (this.brandName === '') {
        alert('검색어를 입력해주세요!');
        this.brandList = [];
      } else {
        // console.log(this.brandListData);
        this.brandList = this.brandListData.filter((brand) => {
          return (brand.brandNm.toLowerCase().includes(this.brandName.toLowerCase()));
        });

        if (this.brandList.length < 1) {
          alert('검색 결과가 존재하지 않습니다.');
        }
        // console.log(this.brandList);
      }
    },
    checktBrand (item) {
      this.checkFranchiseStatus = true;
      this.brandInfo = item;
    },
    selectBrand () {
      this.$emit('insertBrand', this.brandInfo);
    }
  }
};
</script>

<style scoped>
  .modal_close_btn2:hover {
    cursor: pointer;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.08);
  }
  .map-area .searchbox {
    top: 0;
    left: 0;
    height: 370px;
    z-index: 10000;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top:24px;
  }
  .map-area .searchbox .spaceNm {
    color: #fff;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .map-area .searchbox::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  .map-area .searchbox::-webkit-scrollbar-thumb {
    background: #555;
  }
  .map-area .searchbox::-webkit-scrollbar-track{
    background: #ccc;
  }
  .search_input{
    position: relative;
  }
  .search_img {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
  @media all and (max-width: 500px) {
    .map-area .searchbox {
      height: 660px;
    }
    .map-area .searchbox .spaceNm {
      font-size: 16px;
    }
    .search_img {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
</style>
