<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                IP 스피커를 사용하지 않는 건물형 스페이스는<br/>최대 1개만 등록 가능합니다.
            </h3>
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'checkDontUseIpSpeakerModal'
};
</script>

<style scoped>
  @media all and (max-width: 500px) {
    .single-file-check p {
      font-size: 15px !important;
      color: #FF2D55;
      font-weight: 400;
    }
  }
</style>
