<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <p style="padding-bottom: 24px;margin-bottom:0px;" v-html="spaceInfo.spaceNm + '은(는) 이미 등록된 스페이스입니다.<br/>그래도 계속하시겠습니까?'" />
            <div class="single-file-btn">
                <p @click="$emit('closeModal')">아니오</p>
                <p :class="{ disabled: disabledInsert === true }" @click="$emit('closeMap')" id="color-text">네</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'checkSpaceNm',
  props: {
    spaceInfo: Object,
    disabledInsert: Boolean
  }
};
</script>

<style scoped>
  .disabled { pointer-events: none; }
  .single-file-box {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
  }
  .single-file-box h3 {
    padding-bottom: 24px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
  .single-file-box p {
    color: rgba(255, 255, 255, 0.87);
    margin: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: Noto Sans KR,LaTo,"sans-serif";
    font-weight: 400;
    letter-spacing: -.005em;
  }
  .single-file-btn {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single-file-btn p {
    width: 50%;
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
  }
  .single-file-btn #color-text {
    color: #FF2D55;
    font-weight: 400;
  }
  @media all and (max-width: 500px) {
    .single-file-box h3 {
      font-size: 16px;
      padding-top: 32px;
      text-align: center;
    }
    .single-file-btn p {
      font-size: 14px;
    }
    .single-file-btn #color-text {
      font-size: 14px;
    }
  }
</style>
