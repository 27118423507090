<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>개인정보 제3자 제공 동의</h3>
            <p class="single-file-content pc">
                제공받는 자: 브랜드별 본사 담당자<br />
                제공 항목: 매장명, 매장주소, 연락처, 큐레이션 정보<br />
                제공 목적: 본사의 스페이스별 음악 재생 현황 관리<br />
                보유 기간: 사용자의 서비스 이용 종료 시 까지<br />
                <br>
                고객님은 위 개인정보 제3자 제공에 대한 동의를 거부하실 권리가 있으며, 동의를 거부하실 경우 본사 연결 기능 이용이 제한됩니다.<br />
                <br>
                위 내용에 동의하시겠습니까?
            </p>
            <p class="single-file-content mo">
                제공받는 자: 브랜드별 본사 담당자<br />
                제공 항목: 매장명, 매장주소, 연락처, 큐레이션 정보<br />
                제공 목적: 본사의 스페이스별 음악 재생 현황 관리<br />
                보유 기간: 사용자의 서비스 이용 종료 시 까지<br /><br />
                고객님은 위 개인정보 제3자 제공에 대한 동의를<br>거부하실 권리가 있으며, 동의를 거부하실 경우<br>본사 연결 기능 이용이 제한됩니다.<br /><br />
                위 내용에 동의하시겠습니까?
            </p>
            <div class="single-file-btn">
                <p @click="$emit('closeCheckFranchise')">아니오</p>
                <p id="color-text" @click="accept">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'checkFranchise',
  methods: {
    accept () {
      this.$emit('accept');
    }
  }
});
</script>
<style scoped>
.mo {
  display: none;
}

@media all and (max-width: 500px) {
  .pc {
    display: none;
  }

  .mo {
    display: block !important;
  }
}
</style>
